import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import TabsLayout from './tabs.mdx';
import InternalLink from '../../../components/InternalLink';
export const _frontmatter = {
  "slug": "/components/phone-input/accessibility",
  "title": "PhoneInput - Accessibility"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "accessibility",
      "style": {
        "position": "relative"
      }
    }}>{`Accessibility`}<a parentName="h2" {...{
        "href": "#accessibility",
        "aria-label": "accessibility permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "pay-attention-to",
      "style": {
        "position": "relative"
      }
    }}>{`Pay attention to`}<a parentName="h3" {...{
        "href": "#pay-attention-to",
        "aria-label": "pay attention to permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul">{`HDS Phone number input supports HTML input `}<inlineCode parentName="li">{`pattern`}</inlineCode>{` attribute. It can be used to specify an exact format for the phone number.`}
        <ul parentName="li">
          <li parentName="ul"><strong parentName="li">{`Note that pattern is not visible to regular users in any way.`}</strong>{` Some of the screen readers informs the user if the input does not match the pattern. This attribute mostly increases user experience for screen reader users.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`If multiple phone numbers are asked in the same view, make sure they are labelled descriptively or have an extra fieldset label linked to the input with `}<inlineCode parentName="li">{`aria-desribedby`}</inlineCode>{`.`}
        <ul parentName="li">
          <li parentName="ul">{`You can view an example of this on usage page `}<InternalLink href="/components/phone-input#with-a-country-code-selection" mdxType="InternalLink">{`phone input with a country code selection variant`}</InternalLink></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      